import React, { useState, Component } from "react"
import Layout from "../components/layout"

import { Spring } from "react-spring/renderprops"
import Header from "../components/header"
import webIllustration from "../images/asset15.svg"
import softIllustration from "../images/asset16.svg"
import appIllustration from "../images/asset17.svg"
import electron from "../images/Electron.svg"
import flutter from "../images/flutterio-icon.svg"
import VisibilitySensor from "react-visibility-sensor"

import {
  DiReact,
  DiAngularSimple,
  DiNodejsSmall,
  DiPython,
  DiPhotoshop,
  DiIllustrator
} from "react-icons/di"

export class services extends Component {
  state = { visible: false, visible2: false, visible3: false, visible4: false, visible5:false, visible6:false }

  render() {
    return (
      <Layout>
        <Header path={this.props.path} />
        <div className="web_development">
          <div className="container">
            <div>
              <div className="row">
                <div className="col-md-6 mt-md-5">
                  <h1 className="heading3">Web Development</h1>
                  <p className="description web_development__description mt-4">
                  Web development is one of our primary services that we offer understanding our clients’ businesses and what exactly their websites need. We build websites aiming to the searching theories of the target audiences under different businesses. Built with the best-in-class and unique designs for every website, we make it optimum for search engines as well as for users. 


                  </p>
                  <p className="description web_development__description mt-4">
                  We have a team of hard-working and skillful web designers who are capable of building excellent both front-end and back-end features for all kinds of websites. 
                  </p>
                  <div className="row justify-content-start  web_development__toolkit">
                    <div className="col">
                      <h2 className="heading2 web_development__heading">
                        Technologies we use
                      </h2>
                    </div>
                  </div>
                  <div className="row flex-column align-items-start toolkit ">
                    <div className="col ml-3">
                      <div className="row">
                        <Spring
                          config={{
                            delay: 200,
                            tension: 200,
                            friction: 100,
                          }}
                          from={{
                            opacity: 0,
                          }}
                          to={{
                            opacity: 1,
                          }}
                        >
                          {props => (
                            <DiReact
                              style={props}
                              className="toolkit__icon toolkit__icon-1"
                            />
                          )}
                        </Spring>

                        <Spring
                          config={{
                            delay: 300,
                            tension: 200,
                            friction: 100,
                          }}
                          from={{
                            opacity: 0,
                          }}
                          to={{
                            opacity: 1,
                          }}
                        >
                          {props => (
                            <DiAngularSimple
                              style={props}
                              className="toolkit__icon toolkit__icon-2"
                            />
                          )}
                        </Spring>

                        <Spring
                          config={{
                            delay: 500,
                            tension: 200,
                            friction: 100,
                          }}
                          from={{
                            opacity: 0,
                          }}
                          to={{
                            opacity: 1,
                          }}
                        >
                          {props => (
                            <DiNodejsSmall
                              style={props}
                              className="toolkit__icon toolkit__icon-3"
                            />
                          )}
                        </Spring>

                        <Spring
                          config={{
                            delay: 700,
                            tension: 200,
                            friction: 100,
                          }}
                          from={{
                            opacity: 0,
                          }}
                          to={{
                            opacity: 1,
                          }}
                        >
                          {props => (
                            <DiPython
                              style={props}
                              className="toolkit__icon toolkit__icon-4"
                            />
                          )}
                        </Spring>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 web_development__illustration">
                  <Spring
                    config={{
                      tension: 200,
                      friction: 100,
                    }}
                    from={{
                      opacity: 0,
                      transform: "translateY(10rem)",
                    }}
                    to={{
                      opacity: 1,
                      transform: "translateY(0rem)",
                    }}
                  >
                    {props => (
                      <img
                        style={props}
                        src={webIllustration}
                        className="web_development__img"
                        alt="web_illustration"
                      />
                    )}
                  </Spring>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="software">
          <div className="container software_development ">
            <div className="row">
              <div className="col-md-6 software_development__illustration">
                <VisibilitySensor
                  partialVisibility
                  onChange={visible => this.setState({ visible })}
                  active={!this.state.visible}
                >
                  {({ isVisible }) => (
                    <Spring
                      config={{
                        tension: 200,
                        friction: 100,
                      }}
                      from={{
                        opacity: 0,
                        transform: "translateY(10rem)",
                      }}
                      to={{
                        opacity: isVisible ? 1 : 0,
                        transform: isVisible
                          ? "translateY(0rem)"
                          : "translateY(0rem)",
                      }}
                    >
                      {props => (
                        <img
                          style={props}
                          src={softIllustration}
                          className="web_development__img"
                          alt="web_illustration"
                        />
                      )}
                    </Spring>
                  )}
                </VisibilitySensor>
              </div>

              <div className="col-md-6 software_development__description">
                <h1 className="heading3 software_development__heading">
                  Software Development
                </h1>
                <p className="description software_development__paragraph mt-4">
                Software development is one of our core services that is offered after understanding and analyzing the needs of the clients’ companies. We design, develop, test, and deliver until we satisfy our clients with our creations. We also recommend software up-gradation to our clients’ existing systems whenever required. We plan and produce each piece of an application separately making sure that those perform best together.
                </p>

                <div
                  className="row justify-content-start  software_development__toolkit"
                  style={{ marginTop: "5rem" }}
                >
                  <div className="col">
                    <h2 className="heading2 software_development__toolkit-heading">
                      Technologies we use
                    </h2>
                  </div>
                </div>
                <div className="row flex-column align-items-start software_development__tools toolkit">
                  <div className="col ml-3">
                    <div className="row">
                      <VisibilitySensor
                        // onChange={visible =>
                        //   this.setState({ visible2: visible })
                        // }
                        active={!this.state.visible2}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 200,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                              <DiReact
                                style={props}
                                className="toolkit__icon toolkit__iconsoft-1"
                              />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor>

                      <VisibilitySensor
                        // onChange={visible =>
                        //   this.setState({ visible2: visible })
                        // }
                        active={!this.state.visible2}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 500,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                              <img
                                style={{ ...props, fill: "rgb(5, 17, 124)" }}
                                src={electron}
                                height="40"
                              />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor>

                      <VisibilitySensor
                        onChange={visible =>
                          this.setState({ visible2: visible })
                        }
                        active={!this.state.visible2}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 700,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                              <DiNodejsSmall
                                style={props}
                                className="toolkit__icon toolkit__iconsoft-3"
                              />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="app_development">
          <div className="container">
            <div className="app_development__content">
              <div className="row">
                <div className="col-md-6 mt-5">
                  <h1 className="heading3 app_development__heading">
                    Web Design
                  </h1>
                  <p className="description web_development__description mt-4">
                  Web design is our area of strength where our designers deliver the best of their creativity. We aim to keep the web page visitors engaged and stick to the pages we create for our customers. We ensure to reach the target audience through their search by implementing best-in-class technologies.

                  </p>
                  <p className="description web_development__description mt-4">
                  We try best to help our clients meet their goals and add values to their businesses through the website designs. 


                  </p>

                  <div className="row justify-content-start app_development__toolkit">
                    <div className="col">
                      <h2 className="heading2 app_development__toolkit-heading">
                        Technologies we use
                      </h2>
                    </div>
                  </div>
                  <div className="row flex-column align-items-start toolkit ">
                    <div className="col ml-3">
                      <div className="row">
                        <VisibilitySensor
                          // onChange={visible =>
                          //   this.setState({ visible2: visible })
                          // }
                          active={!this.state.visible3}
                        >
                          {({ isVisible }) => (
                            <Spring
                              config={{
                                delay: 200,
                                tension: 200,
                                friction: 100,
                              }}
                              from={{
                                opacity: 0,
                              }}
                              to={{
                                opacity: isVisible ? 1 : 0,
                              }}
                            >
                              {props => (
                                <DiPhotoshop
                                  style={props}
                                  className="toolkit__icon toolkit__icon-1"
                                />
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor>

                        {/* <VisibilitySensor
                          // onChange={visible =>
                          //   this.setState({ visible2: visible })
                          // }
                          active={!this.state.visible3}
                        >
                          {({ isVisible }) => (
                            <Spring
                              config={{
                                delay: 500,
                                tension: 200,
                                friction: 100,
                              }}
                              from={{
                                opacity: 0,
                              }}
                              to={{
                                opacity: isVisible ? 1 : 0,
                              }}
                            >
                              {props => (
                                <img style={props} src={flutter} height="35" />
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor> */}

                        <VisibilitySensor
                          // onChange={visible =>
                          //   this.setState({ visible3: visible })
                          // }
                          active={!this.state.visible3}
                        >
                          {({ isVisible }) => (
                            <Spring
                              config={{
                                delay: 700,
                                tension: 200,
                                friction: 100,
                              }}
                              from={{
                                opacity: 0,
                              }}
                              to={{
                                opacity: isVisible ? 1 : 0,
                              }}
                            >
                              {props => (
                                <DiIllustrator
                                  style={props}
                                  className="toolkit__icon toolkit__icon-3"
                                />
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 web_development__illustration">
                  <VisibilitySensor
                    // partialVisibility
                    onChange={visible => this.setState({ visible3: visible })}
                    active={!this.state.visible3}
                  >
                    {({ isVisible }) => (
                      <Spring
                        config={{
                          tension: 200,
                          friction: 100,
                        }}
                        from={{
                          transform: "translateY(-70rem)",
                        }}
                        to={{
                          opacity: isVisible ? 1 : 0,
                          transform: isVisible
                            ? "translateY(0rem)"
                            : "translateY(0rem)",
                        }}
                      >
                        {props => (
                          <img
                            style={props}
                            src={appIllustration}
                            className="web_development__img"
                            alt="web_illustration"
                          />
                        )}
                      </Spring>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container software_development ">
            <div className="row">
              <div className="col-md-6 software_development__illustration">
                <VisibilitySensor
                  partialVisibility
                  onChange={visible => this.setState({ visible })}
                  active={!this.state.visible4}
                >
                  {({ isVisible }) => (
                    <Spring
                      config={{
                        tension: 200,
                        friction: 100,
                      }}
                      from={{
                        opacity: 0,
                        transform: "translateY(10rem)",
                      }}
                      to={{
                        opacity: isVisible ? 1 : 0,
                        transform: isVisible
                          ? "translateY(0rem)"
                          : "translateY(0rem)",
                      }}
                    >
                      {props => (
                        <img
                          style={props}
                          src={softIllustration}
                          className="web_development__img"
                          alt="web_illustration"
                        />
                      )}
                    </Spring>
                  )}
                </VisibilitySensor>
              </div>

              <div className="col-md-6 software_development__description">
                <h1 className="heading3 software_development__heading">
                  Graphic Design
                </h1>
                <p className="description software_development__paragraph mt-4">
                Graphic design is an integral part of our activity that we offer by creating the quality design as required by our clients. We try to learn and understand the exact design our clients’ company needs and based on our understanding we deliver our product. Our outstanding design enhances the essence of our clients’ businesses as great designs help in various aspects of a business. 


                </p>
                <p className="description software_development__paragraph mt-4">
                Our dedicated team of expert graphic designers creates interesting designs that help businesses to deliver better in different areas of their work. 


                </p>

                <div
                  className="row justify-content-start  software_development__toolkit"
                  style={{ marginTop: "5rem" }}
                >
                  <div className="col">
                    <h2 className="heading2 software_development__toolkit-heading">
                      Technologies we use
                    </h2>
                  </div>
                </div>
                <div className="row flex-column align-items-start software_development__tools toolkit">
                  <div className="col ml-3">
                    <div className="row">
                      <VisibilitySensor
                        // onChange={visible =>
                        //   this.setState({ visible2: visible })
                        // }
                        active={!this.state.visible4}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 200,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                              <DiPhotoshop
                                style={props}
                                className="toolkit__icon toolkit__iconsoft-1"
                              />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor>

                      <VisibilitySensor
                        onChange={visible =>
                          this.setState({ visible2: visible })
                        }
                        active={!this.state.visibl4}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 500,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                               <DiIllustrator
                               style={props}
                               className="toolkit__icon toolkit__iconsoft-1"
                             />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor>

                      {/* <VisibilitySensor
                        onChange={visible =>
                          this.setState({ visible2: visible })
                        }
                        active={!this.state.visible2}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 700,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                              <DiNodejsSmall
                                style={props}
                                className="toolkit__icon toolkit__iconsoft-3"
                              />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="app_development">
          <div className="container">
            <div className="app_development__content">
              <div className="row">
                <div className="col-md-6 mt-5">
                  <h1 className="heading3 app_development__heading">
                    Search Engine Optimization(SEO)
                  </h1>
                  <p className="description web_development__description mt-4">
                  Search Engine Optimization (SEO) needs in-depth knowledge and we are all set to deliver the best to our clients in this segment. Starting from an SEO strategy after going through thorough research and understanding the market towards which our clients are operating, we offer SEO that brings results to our clients’ plates. Our team experts follow the latest and most trending approach towards bringing the best SEO strategy with implementation to help our clients’ pages get maximum traffic and lead as necessary. We provide both on-page and off-page activities to help a business grow in the digital platform.


                  </p>
                 
                  <div className="row justify-content-start app_development__toolkit">
                    <div className="col">
                      <h2 className="heading2 app_development__toolkit-heading">
                        Technologies we use
                      </h2>
                    </div>
                  </div>
                  <div className="row flex-column align-items-start toolkit ">
                    <div className="col ml-3">
                      <div className="row">
                        <VisibilitySensor
                          onChange={visible =>
                            this.setState({ visible2: visible })
                          }
                          active={!this.state.visible5}
                        >
                          {({ isVisible }) => (
                            <Spring
                              config={{
                                delay: 200,
                                tension: 200,
                                friction: 100,
                              }}
                              from={{
                                opacity: 0,
                              }}
                              to={{
                                opacity: isVisible ? 1 : 0,
                              }}
                            >
                              {props => (
                                <DiPhotoshop
                                  style={props}
                                  className="toolkit__icon toolkit__icon-1"
                                />
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor>

                        {/* <VisibilitySensor
                          // onChange={visible =>
                          //   this.setState({ visible2: visible })
                          // }
                          active={!this.state.visible3}
                        >
                          {({ isVisible }) => (
                            <Spring
                              config={{
                                delay: 500,
                                tension: 200,
                                friction: 100,
                              }}
                              from={{
                                opacity: 0,
                              }}
                              to={{
                                opacity: isVisible ? 1 : 0,
                              }}
                            >
                              {props => (
                                <img style={props} src={flutter} height="35" />
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor> */}

                        <VisibilitySensor
                          // onChange={visible =>
                          //   this.setState({ visible3: visible })
                          // }
                          active={!this.state.visible5}
                        >
                          {({ isVisible }) => (
                            <Spring
                              config={{
                                delay: 700,
                                tension: 200,
                                friction: 100,
                              }}
                              from={{
                                opacity: 0,
                              }}
                              to={{
                                opacity: isVisible ? 1 : 0,
                              }}
                            >
                              {props => (
                                <DiIllustrator
                                  style={props}
                                  className="toolkit__icon toolkit__icon-3"
                                />
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 web_development__illustration">
                  <VisibilitySensor
                    // partialVisibility
                    onChange={visible => this.setState({ visible3: visible })}
                    active={!this.state.visible5}
                  >
                    {({ isVisible }) => (
                      <Spring
                        config={{
                          tension: 200,
                          friction: 100,
                        }}
                        from={{
                          transform: "translateY(-70rem)",
                        }}
                        to={{
                          opacity: isVisible ? 1 : 0,
                          transform: isVisible
                            ? "translateY(0rem)"
                            : "translateY(0rem)",
                        }}
                      >
                        {props => (
                          <img
                            style={props}
                            src={appIllustration}
                            className="web_development__img"
                            alt="web_illustration"
                          />
                        )}
                      </Spring>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container software_development ">
            <div className="row">
              <div className="col-md-6 software_development__illustration">
                <VisibilitySensor
                  partialVisibility
                  onChange={visible => this.setState({ visible })}
                  active={!this.state.visible4}
                >
                  {({ isVisible }) => (
                    <Spring
                      config={{
                        tension: 200,
                        friction: 100,
                      }}
                      from={{
                        opacity: 0,
                        transform: "translateY(10rem)",
                      }}
                      to={{
                        opacity: isVisible ? 1 : 0,
                        transform: isVisible
                          ? "translateY(0rem)"
                          : "translateY(0rem)",
                      }}
                    >
                      {props => (
                        <img
                          style={props}
                          src={softIllustration}
                          className="web_development__img"
                          alt="web_illustration"
                        />
                      )}
                    </Spring>
                  )}
                </VisibilitySensor>
              </div>

              <div className="col-md-6 software_development__description">
                <h1 className="heading3 software_development__heading">
                  Digital Marketing
                </h1>
                <p className="description software_development__paragraph mt-4">
                Digital marketing is the most important aspect of our business offerings where we offer our clients from scratch to the end. We offer social media strategy, content strategy, and marketing strategy for the different business areas of specialization. We consider all angles of business by understanding the target audience and using different digital platforms to increase the reach to audiences as well as fulfill the business objectives. We offer both B2B and B2C aspects along with building brands, creating awareness, maintaining consistency, generating leads, and bringing the best ROI. We work for regular campaigns as per the need of our customers and offer results-driven services for different segments of the digital marketing space.



                </p>
               

                <div
                  className="row justify-content-start  software_development__toolkit"
                  style={{ marginTop: "5rem" }}
                >
                  <div className="col">
                    <h2 className="heading2 software_development__toolkit-heading">
                      Technologies we use
                    </h2>
                  </div>
                </div>
                <div className="row flex-column align-items-start software_development__tools toolkit">
                  <div className="col ml-3">
                    <div className="row">
                      <VisibilitySensor
                        // onChange={visible =>
                        //   this.setState({ visible2: visible })
                        // }
                        active={!this.state.visible6}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 200,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                              <DiPhotoshop
                                style={props}
                                className="toolkit__icon toolkit__iconsoft-1"
                              />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor>

                      <VisibilitySensor
                        onChange={visible =>
                          this.setState({ visible2: visible })
                        }
                        active={!this.state.visibl6}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 500,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                               <DiIllustrator
                               style={props}
                               className="toolkit__icon toolkit__iconsoft-1"
                             />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor>

                      {/* <VisibilitySensor
                        onChange={visible =>
                          this.setState({ visible2: visible })
                        }
                        active={!this.state.visible2}
                      >
                        {({ isVisible }) => (
                          <Spring
                            config={{
                              delay: 700,
                              tension: 200,
                              friction: 100,
                            }}
                            from={{
                              opacity: 0,
                            }}
                            to={{
                              opacity: isVisible ? 1 : 0,
                            }}
                          >
                            {props => (
                              <DiNodejsSmall
                                style={props}
                                className="toolkit__icon toolkit__iconsoft-3"
                              />
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default services
